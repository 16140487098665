import Home from '../pages/site/home';
import SystemCheck from '../pages/system/check';
import NotFoundPage from '../pages/not_found_page';
// Painel
import HomePainel from '../pages/home_painel';
import SemPermissao from '../pages/sem_permissao';
import Usuarios from '../pages/usuarios';
import UsuariosAlterar from '../pages/usuarios_alterar';
import Perfis from '../pages/perfis';
import PerfisAlterar from '../pages/perfis_alterar';
import Departamentos from '../pages/departamentos';
import DepartamentosAlterar from '../pages/departamentos_alterar';
import OrgaosEmpresas from '../pages/orgaos_empresas';
import OrgaosEmpresasAlterar from '../pages/orgaos_empresas_alterar';
import PerfilAcoes from '../pages/acoes';
import PerfilAcoesAlterar from '../pages/acoes_alterar';
import Funcionalidades from '../pages/funcionalidades';
import FuncionalidadesAlterar from '../pages/funcionalidades_alterar';
import TiposServidor from '../pages/tipos_servidores';
import TiposServidorAlterar from '../pages/tipos_servidores_alterar';
import CategoriasCNH from '../pages/categorias_cnh';
import CategoriasCNHAlterar from '../pages/categorias_cnh_alterar';
import { CidadesListar, CidadesAlterar } from '../pages/cidades';
import Estados from '../pages/estados';
import EstadosAlterar from '../pages/estados_alterar';
import GrausEscolaridade from '../pages/graus_escolaridade';
import GrausEscolaridadeAlterar from '../pages/graus_escolaridade_alterar';
import NiveisEscolaridade from '../pages/niveis_escolaridade';
import NiveisEscolaridadeAlterar from '../pages/niveis_escolaridade_alterar';
import OrgaosEmissoresRG from '../pages/orgaos_emissores_rg';
import OrgaosEmissoresRGAlterar from '../pages/orgaos_emissores_rg_alterar';
import EstadosCivis from '../pages/estados_civis';
import EstadosCivisAlterar from '../pages/estados_civis_alterar';
import TiposLogradouros from '../pages/tipos_logradouros';
import TiposLogradourosAlterar from '../pages/tipos_logradouros_alterar';
import OrientacoesSexuais from '../pages/orientacoes_sexuais';
import OrientacoesSexuaisAlterar from '../pages/orientacoes_sexuais_alterar';
import IdenditadesGenero from '../pages/identidade_genero';
import IdenditadesGeneroAlterar from '../pages/identidade_genero_alterar';
import TiposRacasCores from '../pages/tipos_racas_cores';
import TiposRacasCoresAlterar from '../pages/tipos_racas_cores_alterar';
import Instituicoes from '../pages/contratos_convenios/instituicoes';
import InstituicoesAlterar from '../pages/contratos_convenios/instituicoes_alterar';
import TiposDocumentos from '../pages/contratos_convenios/tipos_documentos';
import TiposDocumentosAlterar from '../pages/contratos_convenios/tipos_documentos_alterar';
import TiposContratos from '../pages/contratos_convenios/tipos_contratos';
import TiposContratosAlterar from '../pages/contratos_convenios/tipos_contratos_alterar';
import TiposEmpresas from '../pages/contratos_convenios/tipos_empresas';
import TiposEmpresasAlterar from '../pages/contratos_convenios/tipos_empresas_alterar';
import AgenciasBancarias from '../pages/contratos_convenios/agencias_bancarias';
import AgenciasBancariasAlterar from '../pages/contratos_convenios/agencias_bancarias_alterar';
import TiposContasBancarias from '../pages/contratos_convenios/tipos_contas_bancarias';
import TiposContasBancariasAlterar from '../pages/contratos_convenios/tipos_contas_bancarias_alterar';
import Logout from '../pages/logout';
var PrefixoAdmin = '/painel';
export var ROUTES = {
    // site
    HOME_SITE: "/",
    LOGOUT: "".concat(PrefixoAdmin, "/logout"),
    SEM_PERMISSAO: "/sem-permissao",
    SYSTEM_CHECK: "/system/check",
    USUARIOS: "".concat(PrefixoAdmin, "/usuarios"),
    PERFIS: "".concat(PrefixoAdmin, "/perfis"),
    DEPARTAMENTOS: "".concat(PrefixoAdmin, "/departamentos"),
    ORGAOS_EMPRESAS: "".concat(PrefixoAdmin, "/orgaos-empresas"),
    ACOES: "".concat(PrefixoAdmin, "/acoes"),
    FUNCIONALIDADES: "".concat(PrefixoAdmin, "/funcionalidades"),
    TIPOS_SERVIDORES: "".concat(PrefixoAdmin, "/tipos-servidores"),
    CATEGORIAS_CNH: "".concat(PrefixoAdmin, "/categorias-cnh"),
    CIDADES: "".concat(PrefixoAdmin, "/cidades"),
    ESTADOS: "".concat(PrefixoAdmin, "/estados"),
    ESTADOS_CIVIS: "".concat(PrefixoAdmin, "/estados-civis"),
    GRAUS_ESCOLARIDADE: "".concat(PrefixoAdmin, "/graus-escolaridade"),
    IDENTIDADES_GENERO: "".concat(PrefixoAdmin, "/identidades-genero"),
    NIVEIS_ESCOLARIDADE: "".concat(PrefixoAdmin, "/niveis-escolaridade"),
    ORGAOS_EMISSORES_RG: "".concat(PrefixoAdmin, "/orgaos-emissores-rg"),
    ORIENTACOES_SEXUAIS: "".concat(PrefixoAdmin, "/orientacoes-sexuais"),
    TIPOS_LOGRADOUROS: "".concat(PrefixoAdmin, "/tipos-logradouros"),
    TIPOS_RACAS_CORES: "".concat(PrefixoAdmin, "/tipos-racas-cores"),
    //CONTRATOS_CONVENIOS
    CC_INSTITUICOES: "".concat(PrefixoAdmin, "/instituicoes"),
    CC_TIPOS_DOCUMENTOS: "".concat(PrefixoAdmin, "/tipos-termos-documentos"),
    CC_TIPOS_EMPRESAS: "".concat(PrefixoAdmin, "/tipos-empresas"),
    CC_TIPOS_CONTRATOS: "".concat(PrefixoAdmin, "/tipos-contratos"),
    AGENCIAS_BANCARIAS: "".concat(PrefixoAdmin, "/agencias-bancarias"),
    TIPOS_CONTAS_BANCARIAS: "".concat(PrefixoAdmin, "/tipos-contas-bancarias"),
    //painel
    HOME_PAINEL: "".concat(PrefixoAdmin),
};
export var PAGINAS = {
    // site
    HOME_SITE: "Home Site",
    SEM_PERMISSAO: "Sem Permiss\u00E3o",
    SYSTEM_CHECK: "System Check",
    USUARIOS: "Usu\u00E1rios",
    PERFIS: "Perfis",
    DEPARTAMENTOS: "Departamentos",
    ORGAOS_EMPRESAS: "Org\u00E3os / Empresas",
    ACOES: "A\u00E7\u00F5es",
    FUNCIONALIDADES: "Funcionalidades",
    TIPOS_SERVIDORES: "Tipos de Servidores",
    CATEGORIAS_CNH: "Categorias de CNH",
    CIDADES: "Cidades",
    ESTADOS: "Estados",
    ESTADOS_CIVIS: "Estados Civis",
    GRAUS_ESCOLARIDADE: "Graus de Escolaridade",
    IDENTIDADES_GENERO: "Identidades de G\u00EAnero",
    NIVEIS_ESCOLARIDADE: "N\u00EDveis de Escolaridade",
    ORGAOS_EMISSORES_RG: "\u00D3rg\u00E3os Emissores de RG",
    ORIENTACOES_SEXUAIS: "Orienta\u00E7\u00F5es Sexuais",
    TIPOS_LOGRADOUROS: "Tipos de Logradouros",
    TIPOS_RACAS_CORES: "Tipos de Ra\u00E7as / Cores",
    CC_INSTITUICOES: "Institui\u00E7\u00F5es",
    CC_INSTITUICOES_DADOS: "Institui\u00E7\u00F5es - Dados",
    CC_INSTITUICOES_DOCUMENTOS: "Institui\u00E7\u00F5es - Documentos",
    CC_INSTITUICOES_TERMOS: "Institui\u00E7\u00F5es - Termos / Contratos",
    CC_TIPOS_DOCUMENTOS: "Tipos de Termos / Documentos",
    CC_TIPOS_EMPRESAS: "Tipos de Empresas",
    CC_TIPOS_CONTRATOS: "Tipos de Contratos",
    AGENCIAS_BANCARIAS: "Ag\u00EAncias Banc\u00E1rias",
    TIPOS_CONTAS_BANCARIAS: "Tipos de Contas Banc\u00E1rias",
    NOTIFICACOES: "Notifica\u00E7\u00F5es",
    //painel
    HOME_PAINEL: "".concat(PrefixoAdmin),
};
var RoutesConfig = [
    {
        path: '*',
        element: NotFoundPage,
    },
    {
        path: ROUTES.SYSTEM_CHECK,
        element: SystemCheck,
    },
    {
        path: ROUTES.LOGOUT,
        element: Logout,
    },
    {
        path: ROUTES.HOME_SITE,
        element: Home,
    },
    {
        path: ROUTES.HOME_PAINEL,
        element: HomePainel,
    },
    {
        path: ROUTES.SEM_PERMISSAO,
        element: SemPermissao,
    },
    {
        path: ROUTES.USUARIOS,
        element: Usuarios,
    },
    {
        path: ROUTES.USUARIOS + '/:id?/:modo',
        element: UsuariosAlterar,
    },
    {
        path: ROUTES.PERFIS,
        element: Perfis,
    },
    {
        path: ROUTES.PERFIS + '/:id?/:modo',
        element: PerfisAlterar,
    },
    {
        path: ROUTES.DEPARTAMENTOS,
        element: Departamentos,
    },
    {
        path: ROUTES.DEPARTAMENTOS + '/:id?/:modo',
        element: DepartamentosAlterar,
    },
    {
        path: ROUTES.ORGAOS_EMPRESAS,
        element: OrgaosEmpresas,
    },
    {
        path: ROUTES.ORGAOS_EMPRESAS + '/:id?/:modo',
        element: OrgaosEmpresasAlterar,
    },
    {
        path: ROUTES.ACOES,
        element: PerfilAcoes,
    },
    {
        path: ROUTES.ACOES + '/:id?/:modo',
        element: PerfilAcoesAlterar,
    },
    {
        path: ROUTES.FUNCIONALIDADES,
        element: Funcionalidades,
    },
    {
        path: ROUTES.FUNCIONALIDADES + '/:id?/:modo',
        element: FuncionalidadesAlterar,
    },
    {
        path: ROUTES.TIPOS_SERVIDORES,
        element: TiposServidor,
    },
    {
        path: ROUTES.TIPOS_SERVIDORES + '/:id?/:modo',
        element: TiposServidorAlterar,
    },
    {
        path: ROUTES.CATEGORIAS_CNH,
        element: CategoriasCNH,
    },
    {
        path: ROUTES.CATEGORIAS_CNH + '/:id?/:modo',
        element: CategoriasCNHAlterar,
    },
    {
        path: ROUTES.CIDADES,
        element: CidadesListar,
    },
    {
        path: ROUTES.CIDADES + '/:id?/:modo',
        element: CidadesAlterar,
    },
    {
        path: ROUTES.ESTADOS,
        element: Estados,
    },
    {
        path: ROUTES.ESTADOS + '/:id?/:modo',
        element: EstadosAlterar,
    },
    {
        path: ROUTES.GRAUS_ESCOLARIDADE,
        element: GrausEscolaridade,
    },
    {
        path: ROUTES.GRAUS_ESCOLARIDADE + '/:id?/:modo',
        element: GrausEscolaridadeAlterar,
    },
    {
        path: ROUTES.NIVEIS_ESCOLARIDADE,
        element: NiveisEscolaridade,
    },
    {
        path: ROUTES.NIVEIS_ESCOLARIDADE + '/:id?/:modo',
        element: NiveisEscolaridadeAlterar,
    },
    {
        path: ROUTES.ORGAOS_EMISSORES_RG,
        element: OrgaosEmissoresRG,
    },
    {
        path: ROUTES.ORGAOS_EMISSORES_RG + '/:id?/:modo',
        element: OrgaosEmissoresRGAlterar,
    },
    {
        path: ROUTES.ESTADOS_CIVIS,
        element: EstadosCivis,
    },
    {
        path: ROUTES.ESTADOS_CIVIS + '/:id?/:modo',
        element: EstadosCivisAlterar,
    },
    {
        path: ROUTES.TIPOS_LOGRADOUROS,
        element: TiposLogradouros,
    },
    {
        path: ROUTES.TIPOS_LOGRADOUROS + '/:id?/:modo',
        element: TiposLogradourosAlterar,
    },
    {
        path: ROUTES.ORIENTACOES_SEXUAIS,
        element: OrientacoesSexuais,
    },
    {
        path: ROUTES.ORIENTACOES_SEXUAIS + '/:id?/:modo',
        element: OrientacoesSexuaisAlterar,
    },
    {
        path: ROUTES.IDENTIDADES_GENERO,
        element: IdenditadesGenero,
    },
    {
        path: ROUTES.IDENTIDADES_GENERO + '/:id?/:modo',
        element: IdenditadesGeneroAlterar,
    },
    {
        path: ROUTES.TIPOS_RACAS_CORES,
        element: TiposRacasCores,
    },
    {
        path: ROUTES.TIPOS_RACAS_CORES + '/:id?/:modo',
        element: TiposRacasCoresAlterar,
    },
    {
        path: ROUTES.CC_INSTITUICOES,
        element: Instituicoes,
    },
    {
        path: ROUTES.CC_INSTITUICOES + '/:id?/:modo',
        element: InstituicoesAlterar,
    },
    {
        path: ROUTES.CC_TIPOS_CONTRATOS,
        element: TiposContratos,
    },
    {
        path: ROUTES.CC_TIPOS_CONTRATOS + '/:id?/:modo',
        element: TiposContratosAlterar,
    },
    {
        path: ROUTES.CC_TIPOS_DOCUMENTOS,
        element: TiposDocumentos,
    },
    {
        path: ROUTES.CC_TIPOS_DOCUMENTOS + '/:id?/:modo',
        element: TiposDocumentosAlterar,
    },
    {
        path: ROUTES.CC_TIPOS_EMPRESAS,
        element: TiposEmpresas,
    },
    {
        path: ROUTES.CC_TIPOS_EMPRESAS + '/:id?/:modo',
        element: TiposEmpresasAlterar,
    },
    {
        path: ROUTES.AGENCIAS_BANCARIAS,
        element: AgenciasBancarias,
    },
    {
        path: ROUTES.AGENCIAS_BANCARIAS + '/:id?/:modo',
        element: AgenciasBancariasAlterar,
    },
    {
        path: ROUTES.TIPOS_CONTAS_BANCARIAS,
        element: TiposContasBancarias,
    },
    {
        path: ROUTES.TIPOS_CONTAS_BANCARIAS + '/:id?/:modo',
        element: TiposContasBancariasAlterar,
    },
];
export default RoutesConfig;
