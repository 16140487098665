var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useContext } from 'react';
import { Button, IconButton, Typography } from '@mui/material';
import { MODOS } from '../../utils';
import { Box, Badge } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { blue } from '@mui/material/colors';
import AuthContext from '../../contexts/auth';
import NotificacoesContext from '../../contexts/notificacoes';
import { getURLMTLogout } from '../../services/mt_login';
import { Notifications, NotificationsActive } from '@mui/icons-material';
import Notificacoes from '../../pages/notificacoes';
import { PAGINAS } from '../../routes';
var Header = function (props) {
    var pagina = props.pagina, modo = props.modo;
    var _a = useContext(AuthContext) || {
        auth: function () { },
        User: null,
        NomeUsuario: '',
        signOutContext: function () { },
        getMensagens: function () { },
        verificarPermissoesPagina: function () { return false; },
        DEV: false,
    }, auth = _a.auth, User = _a.User, NomeUsuario = _a.NomeUsuario, signOutContext = _a.signOutContext, getMensagens = _a.getMensagens, verificarPermissoesPagina = _a.verificarPermissoesPagina, DEV = _a.DEV;
    var _b = useContext(NotificacoesContext) || {
        possueNovasNotificacoes: false,
        visualizarNotificacoesNovas: function () { },
    }, possueNovasNotificacoes = _b.possueNovasNotificacoes, visualizarNotificacoesNovas = _b.visualizarNotificacoesNovas;
    var _c = useState(false), ExibirNotificacoes = _c[0], setExibirNotificacoes = _c[1];
    var _d = useState(false), PermissaoExibirNotificacoes = _d[0], setPermissaoExibirNotificacoes = _d[1];
    useEffect(function () {
        verificarAuth(); //Verifica se o usuário está logado
        verificarPermissaoNotificacoes();
        exibirMensagens();
        return function () { };
    }, []);
    useEffect(function () {
        verificarPermissaoNotificacoes();
        return function () { };
    }, [User]);
    var verificarAuth = function () {
        if (User) {
            auth(null, pagina, modo);
        }
        else {
            var params = new Proxy(new URLSearchParams(window.location.search), {
                get: function (searchParams, prop) { return searchParams.get(String(prop)); },
            });
            var code = params.code;
            auth(code, pagina, modo); //Caso tenha acabado de logar e esta na Home do Painel
        }
    };
    var verificarPermissaoNotificacoes = function () {
        setPermissaoExibirNotificacoes(verificarPermissoesPagina(PAGINAS.NOTIFICACOES, MODOS.VISUALIZAR));
    };
    var getLogout = function () {
        signOutContext();
        DEV && console.log('getURLMTLogout', getURLMTLogout());
        window.location.href = getURLMTLogout();
    };
    var exibirMensagens = function () {
        var Mensagens = getMensagens();
        for (var index = 0; Mensagens && index < Mensagens.length; index++) {
            var MensagemAux = Mensagens[index];
            alert(MensagemAux);
        }
    };
    var onClickSininho = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            visualizarNotificacoesNovas();
            setExibirNotificacoes(!ExibirNotificacoes);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(Box, { children: [_jsxs(Box, __assign({ height: 50, justifyContent: 'space-around', display: 'flex', gap: 10, p: 1, sx: { bgcolor: 'primary.main' } }, { children: [_jsx(Typography, __assign({ variant: 'h6', color: 'white', noWrap: true }, { children: "SIRS - SISTEMA INTEGRADO DE REINTEGRA\u00C7\u00C3O SOCIAL" })), _jsxs(Box, __assign({ display: 'flex' }, { children: [_jsx(Box, __assign({ bgcolor: blue[50], borderRadius: 1, p: 1, pt: 0.7 }, { children: _jsx(Button, __assign({ onClick: function () {
                                        window.location.href = '/';
                                    }, size: 'small', sx: { minWidth: 120 }, style: { padding: 0, zIndex: 1000 } }, { children: "Voltar ao Site" })) })), _jsx(Box, __assign({ ml: 1 }, { children: _jsx(Typography, __assign({ variant: 'h6', color: 'white' }, { children: NomeUsuario })) })), PermissaoExibirNotificacoes && (_jsx(Box, __assign({ ml: 3 }, { children: _jsx(NotificationBell, { hasNewNotifications: possueNovasNotificacoes, onClick: onClickSininho }) }))), _jsx(Box, __assign({ ml: 3 }, { children: _jsx(IconButton, __assign({ style: { padding: 0, zIndex: 1000 }, onClick: getLogout }, { children: _jsx(LogoutIcon, { sx: { color: 'secondary.main' }, fontSize: 'large' }) })) }))] }))] })), ExibirNotificacoes && _jsx(Notificacoes, { open: ExibirNotificacoes, handleClose: function () { return setExibirNotificacoes(false); } })] }));
};
var NotificationBell = function (_a) {
    var hasNewNotifications = _a.hasNewNotifications, onClick = _a.onClick;
    return (_jsx(IconButton, __assign({ onClick: onClick, "aria-label": 'notifications' }, { children: _jsx(Badge, __assign({ color: 'error', variant: 'dot', invisible: !hasNewNotifications, overlap: 'circular' }, { children: hasNewNotifications ? _jsx(NotificationsActive, { style: { color: '#FA9C2E' } }) : _jsx(Notifications, {}) })) })));
};
export default Header;
