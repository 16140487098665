import React from 'react';
import { InputMask as InputMaskOther } from '@react-input/mask';
import InputMask from 'react-input-mask';
import { TextField } from '@mui/material';

export const InputDados = (props) => {
  const { multiline, select, maxLength, ...rest } = props;
  if (multiline) {
    return <TextField size='small' {...props} minRows={4} fullWidth />;
  } else {
    if (select) {
      return <TextField size='small' {...props} fullWidth />;
    } else {
      return (
        <InputMask size='small' {...rest}>
          {(inputProps) => (
            <TextField
              size='small'
              inputProps={{ ...inputProps, maxLength: maxLength ?? 100 }}
              {...props}
              InputLabelProps={{ shrink: !!props.value }}
              fullWidth
            />
          )}
        </InputMask>
      );
    }
  }
};

export default InputDados;

export const InputDados2 = (props) => {
  const { multiline, select, maxLength, inputProps, ...rest } = props;

  // Combina inputProps fornecidos com maxLength
  const combinedInputProps = {
    ...inputProps, // inputProps personalizados passados pelo usuário
    ...(maxLength && { maxLength }), // Adiciona maxLength se estiver presente
  };

  if (multiline) {
    return <TextField size='small' {...rest} minRows={4} fullWidth inputProps={combinedInputProps} />;
  } else if (select) {
    return <TextField size='small' {...rest} fullWidth inputProps={combinedInputProps} />;
  } else {
    return (
      <InputMask {...rest}>
        {(inputPropsMasked) => (
          <TextField
            size='small'
            {...inputPropsMasked}
            {...rest}
            inputProps={{ ...inputPropsMasked.inputProps, ...combinedInputProps }}
            InputLabelProps={{ shrink: !!props.value }}
            fullWidth
          />
        )}
      </InputMask>
    );
  }
};
