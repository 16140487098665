var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuIcon from '@mui/icons-material/Menu';
import { GlobalStyles, Box, IconButton } from '@mui/material';
import { toggleSidebar } from '../../utils';
var Header = function () {
    return (_jsxs(Box, __assign({ sx: {
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            width: '100vw',
            height: 'var(--Header-height)',
            zIndex: 1000,
            p: 2,
            gap: 1,
            borderBottom: '1px solid',
            borderColor: 'background.level1',
            boxShadow: 'sm',
        } }, { children: [_jsx(GlobalStyles, { styles: function (theme) {
                    var _a;
                    return ({
                        ':root': (_a = {
                                '--Header-height': '52px'
                            },
                            _a[theme.breakpoints.up('md')] = {
                                '--Header-height': '0px',
                            },
                            _a),
                    });
                } }), _jsx(IconButton, __assign({ style: { padding: 0 }, onClick: toggleSidebar }, { children: _jsx(MenuIcon, {}) }))] })));
};
export default Header;
