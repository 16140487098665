import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUsuarioPossuiNovasNotificacoes, setUsuarioVisualizouNotificacoes } from '../../api';
const NotificationContext = createContext(null);

export const NotificacoesProvider = ({ children }) => {
  const [possueNovasNotificacoes, setPossueNovasNotificacoes] = useState(localStorage.getItem('possueNovasNotificacoes') == 'true');

  const procurarNovasNotificacoes = async () => {
    console.log('procurarNovasNotificacoes');
    try {
      const Respota = await getUsuarioPossuiNovasNotificacoes();
      console.log('Resposta : ' + Respota);

      setPossueNovasNotificacoes(Respota);
      localStorage.setItem('possueNovasNotificacoes', Respota ? 'true' : 'false');
      // Salva o timestamp do último check
      localStorage.setItem('lastCheckTimestamp', Date.now().toString());
    } catch (error) {
      console.error('Erro ao verificar novas notificações:', error);
    }
  };

  const visualizarNotificacoesNovas = async () => {
    await setUsuarioVisualizouNotificacoes();
    setPossueNovasNotificacoes(false);
    localStorage.setItem('possueNovasNotificacoes', 'false');
  };

  useEffect(() => {
    const lastCheckTimestamp = parseInt(localStorage.getItem('lastCheckTimestamp') || '0', 10);
    const currentTime = Date.now();
    const timeSinceLastCheck = currentTime - lastCheckTimestamp;
    const intervalDuration = 300000; // 5 minutos em milissegundos
    const initialDelay = Math.max(intervalDuration - timeSinceLastCheck, 0);

    // Primeiro check após o atraso calculado
    const initialTimeoutId = setTimeout(() => {
      procurarNovasNotificacoes();

      // Após o primeiro check, configurar o intervalo regular de 5 minutos
      const intervalId = setInterval(() => {
        procurarNovasNotificacoes();
      }, intervalDuration);

      // Limpa o intervalo ao desmontar o componente
      return () => clearInterval(intervalId);
    }, initialDelay);

    // Limpa o timeout inicial ao desmontar o componente
    return () => clearTimeout(initialTimeoutId);
  }, []);

  return <NotificationContext.Provider value={{ possueNovasNotificacoes, visualizarNotificacoesNovas }}>{children}</NotificationContext.Provider>;
};

export default NotificationContext;
