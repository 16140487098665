// Objetivo: Serviço para autenticação de usuários no portal MT
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { atualizarAcessoUsuario, getUsuarioPermissoes } from '../api';
import { decriptografar } from '../utils';
var DEV = process.env.NODE_ENV === 'development';
var URL_PROJETO = getBaseUrl();
var CI_PROJECT_NAME = (_a = process.env.CI_PROJECT_NAME) !== null && _a !== void 0 ? _a : '';
var URL_MT_LOGIN = (_b = process.env.URL_MT_LOGIN) !== null && _b !== void 0 ? _b : '';
var CONFIG = {
    grant_type: 'authorization_code',
    client_id: CI_PROJECT_NAME,
    redirect_uri: "".concat(URL_PROJETO, "/painel"),
    url_token: "".concat(URL_MT_LOGIN, "/auth/realms/mt-realm/protocol/openid-connect/token"),
    url_userInfo: "".concat(URL_MT_LOGIN, "/auth/realms/mt-realm/protocol/openid-connect/userinfo"),
    url_login: "".concat(URL_MT_LOGIN, "/auth/realms/mt-realm/protocol/openid-connect/auth?client_id=").concat(CI_PROJECT_NAME, "&redirect_uri=").concat(URL_PROJETO, "/painel&response_type=code"),
    url_logout: "".concat(URL_MT_LOGIN, "/auth/realms/mt-realm/protocol/openid-connect/logout?").concat(CI_PROJECT_NAME, "&redirect_uri=").concat(URL_PROJETO, "&response_type=code"),
};
export var verificarUsuarioLogado = function () { return __awaiter(void 0, void 0, void 0, function () {
    var params, code, Resp, RespJson, DadosUsuarioMTLogin, CPF, Resp_1, error_1, error_2;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 18, , 19]);
                //verifica a url de retorno depois de logar
                DEV && console.log('verificarUsuarioLogado');
                params = new Proxy(new URLSearchParams(window.location.search), {
                    get: function (searchParams, prop) { return searchParams.get(String(prop)); },
                });
                code = params.code;
                if (!code) return [3 /*break*/, 16];
                return [4 /*yield*/, fetch(CONFIG.url_token, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: new URLSearchParams({
                            grant_type: CONFIG.grant_type,
                            client_id: CONFIG.client_id,
                            code: code,
                            redirect_uri: CONFIG.redirect_uri,
                        }),
                    })];
            case 1:
                Resp = _b.sent();
                return [4 /*yield*/, Resp.json()];
            case 2:
                RespJson = _b.sent();
                if (!(RespJson && RespJson.access_token)) return [3 /*break*/, 14];
                return [4 /*yield*/, getDadosUsuarioLogadoMTLogin(RespJson.access_token)];
            case 3:
                DadosUsuarioMTLogin = _b.sent();
                if (!DadosUsuarioMTLogin) return [3 /*break*/, 12];
                DEV && console.log('DADOS USUARIO', JSON.stringify(DadosUsuarioMTLogin));
                CPF = (_a = DadosUsuarioMTLogin.cpf) !== null && _a !== void 0 ? _a : DadosUsuarioMTLogin.preferred_username;
                return [4 /*yield*/, getUsuarioPermissoes(CPF, RespJson.access_token)];
            case 4:
                Resp_1 = _b.sent();
                DEV && console.log('Resp', JSON.stringify(Resp_1));
                if (!Resp_1) return [3 /*break*/, 10];
                if (!(Resp_1.status_code == '400')) return [3 /*break*/, 5];
                return [2 /*return*/, __assign({}, Resp_1)];
            case 5:
                _b.trys.push([5, 7, , 8]);
                return [4 /*yield*/, atualizarAcessoUsuario(Resp_1.usuario.id, RespJson.access_token)];
            case 6:
                _b.sent();
                return [3 /*break*/, 8];
            case 7:
                error_1 = _b.sent();
                console.log('ERRO AO ATUALIZAR ACESSO USUARIO', error_1);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/, { usuario: __assign(__assign({}, Resp_1.usuario), DadosUsuarioMTLogin) }];
            case 9: return [3 /*break*/, 11];
            case 10: return [2 /*return*/, { status_code: 400, tipo_erro: 'erro_executar_solicitacao_api' }];
            case 11: return [3 /*break*/, 13];
            case 12: return [2 /*return*/, { status_code: 400, tipo_erro: 'erro_executar_solicitacao_mt_login_dados_usuario' }];
            case 13: return [3 /*break*/, 15];
            case 14: return [2 /*return*/, { status_code: 400, tipo_erro: 'erro_executar_solicitacao_mt_login_token' }];
            case 15: return [3 /*break*/, 17];
            case 16:
                console.log('ERRO: no CODE na resposta MTLogin');
                return [2 /*return*/, { status_code: 400, tipo_erro: 'erro_sem_variavel_code' }];
            case 17: return [3 /*break*/, 19];
            case 18:
                error_2 = _b.sent();
                console.log('ERRO AO BUSCAR USUARIO LOGADO', error_2);
                return [2 /*return*/, { status_code: 400, tipo_erro: 'erro_desconhecido', message: error_2 }];
            case 19: return [2 /*return*/];
        }
    });
}); };
export var getDadosUsuarioLogadoLocalStorage = function () {
    DEV && console.log('getDadosUsuarioLogadoLocalStorage');
    var UsuarioLogadoJSONCripto = localStorage.getItem('usuario_logado');
    if (UsuarioLogadoJSONCripto) {
        var UsuarioLogado = JSON.parse(decriptografar(UsuarioLogadoJSONCripto));
        //console.log('UsuarioLogado', JSON.stringify(UsuarioLogado));
        return UsuarioLogado;
    }
    else {
        return null;
    }
};
export var getTokenAcessoUsuario = function () {
    //DEV && console.log('getDadosUsuarioLogadoLocalStorage');
    var UsuarioLogadoJSONCripto = localStorage.getItem('usuario_logado');
    if (UsuarioLogadoJSONCripto) {
        var UsuarioLogado = JSON.parse(decriptografar(UsuarioLogadoJSONCripto));
        //console.log('UsuarioLogado', JSON.stringify(UsuarioLogado));
        return UsuarioLogado.access_token;
    }
    else {
        return null;
    }
};
export var getDadosUsuarioLogadoMTLogin = function (AcessTokenUsuarioParm) { return __awaiter(void 0, void 0, void 0, function () {
    var AcessTokenUsuario, Resp, RespJson, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                DEV && console.log('getDadosUsuarioLogadoMTLogin');
                AcessTokenUsuario = AcessTokenUsuarioParm !== null && AcessTokenUsuarioParm !== void 0 ? AcessTokenUsuarioParm : getAcessToken();
                if (!AcessTokenUsuario) return [3 /*break*/, 8];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                return [4 /*yield*/, fetch(CONFIG.url_userInfo, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        },
                        body: new URLSearchParams({
                            access_token: AcessTokenUsuario,
                            client_id: CONFIG.client_id,
                        }),
                    })];
            case 2:
                Resp = _a.sent();
                if (!(Resp.status < 400)) return [3 /*break*/, 4];
                return [4 /*yield*/, Resp.json()];
            case 3:
                RespJson = _a.sent();
                DEV && console.log('RespJson', JSON.stringify(RespJson));
                return [2 /*return*/, __assign(__assign({}, RespJson), { access_token: AcessTokenUsuario })];
            case 4: return [2 /*return*/, null];
            case 5: return [3 /*break*/, 7];
            case 6:
                error_3 = _a.sent();
                console.log('ERRO AO OBTER DADOS DO USUARIO', error_3);
                return [2 /*return*/, null];
            case 7: return [3 /*break*/, 9];
            case 8: return [2 /*return*/, null];
            case 9: return [2 /*return*/];
        }
    });
}); };
export var getLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
    var AcessTokenUsuario, Resp, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                AcessTokenUsuario = getAcessToken();
                DEV && console.log('getLogout');
                if (!AcessTokenUsuario) return [3 /*break*/, 5];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, fetch(CONFIG.url_logout, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                        },
                        body: new URLSearchParams({
                            access_token: AcessTokenUsuario,
                            client_id: CONFIG.client_id,
                        }),
                    })];
            case 2:
                Resp = _a.sent();
                if (Resp.status < 400) {
                    return [2 /*return*/, true];
                }
                else {
                    return [2 /*return*/, false];
                }
                return [3 /*break*/, 4];
            case 3:
                error_4 = _a.sent();
                console.log('ERRO AO FAZER LOGOUT', error_4);
                return [2 /*return*/, false];
            case 4: return [3 /*break*/, 6];
            case 5:
                localStorage.removeItem('usuarioLogado');
                return [2 /*return*/, true];
            case 6: return [2 /*return*/];
        }
    });
}); };
export var getAcessToken = function () {
    var UsuarioLogado = getDadosUsuarioLogadoLocalStorage();
    return UsuarioLogado === null || UsuarioLogado === void 0 ? void 0 : UsuarioLogado.access_token;
};
export var getURLMTLogin = function () {
    return CONFIG.url_login;
};
export var getURLMTLogout = function () {
    return CONFIG.url_logout;
};
export var getURLProjeto = function () {
    var _a;
    var URL_PROJETO = (_a = process.env.MT_LOGIN_URL) !== null && _a !== void 0 ? _a : 'http://localhost:3006';
    return URL_PROJETO;
};
function getBaseUrl() {
    var _a = window.location, protocol = _a.protocol, hostname = _a.hostname, port = _a.port;
    return "".concat(protocol, "//").concat(hostname).concat(port ? ":".concat(port) : '');
}
export var getTokenFromCookie = function () {
    var name = 'token=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookies = decodedCookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null; // Se não encontrar o token
};
