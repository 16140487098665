var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useContext } from 'react';
import { GlobalStyles, Box, ListItem, ListItemButton, Divider, List, Typography, styled, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { closeSidebar, FONT_SIZE, getVersao } from '../../utils';
import { PAGINAS, ROUTES } from '../../routes';
import AuthContext from '../../contexts/auth';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ShieldIcon from '@mui/icons-material/Shield';
import StorageIcon from '@mui/icons-material/Storage';
var Sidebar = function () {
    var navigate = useNavigate();
    var _a = useContext(AuthContext) || {
        User: null,
        getAcoesFuncionalidade: function () { return []; },
        DEV: false,
    }, User = _a.User, getAcoesFuncionalidade = _a.getAcoesFuncionalidade, DEV = _a.DEV;
    var _b = React.useState(User === null || User === void 0 ? void 0 : User.permissoes), PermissoesUsuario = _b[0], setPermissoesUsuario = _b[1];
    var mostrarItemMenu = function (Funcionalidade) {
        return PermissoesUsuario === null || PermissoesUsuario === void 0 ? void 0 : PermissoesUsuario.find(function (Item) { return Item.nome == Funcionalidade; });
    };
    useEffect(function () {
        setPermissoesUsuario(User === null || User === void 0 ? void 0 : User.permissoes);
    }, [User]);
    var Toggler = function (_a) {
        var _b = _a.defaultExpanded, defaultExpanded = _b === void 0 ? false : _b, renderToggle = _a.renderToggle, children = _a.children;
        var _c = React.useState(defaultExpanded), open = _c[0], setOpen = _c[1];
        return (_jsxs(React.Fragment, { children: [renderToggle({ open: open, setOpen: setOpen }), _jsx(Box, __assign({ sx: {
                        display: 'grid',
                        gridTemplateRows: open ? '1fr' : '0fr',
                        transition: '0.2s ease',
                        '& > *': {
                            overflow: 'hidden',
                        },
                        paddingLeft: 3,
                    } }, { children: children }))] }));
    };
    var ListItemStyled = styled(ListItem)(function (_a) {
        var theme = _a.theme;
        return ({
            padding: 0,
            margin: 2,
            marginBottom: -2,
            marginLeft: 5,
        });
    });
    var ItemTexto = function (_a) {
        var text = _a.text;
        return (_jsx(ListItemStyled, { children: _jsx(Typography, __assign({ style: { fontSize: FONT_SIZE.mediumLarge } }, { children: text })) }));
    };
    var ItemMenu = function (_a) {
        var icon = _a.icon, text = _a.text, onClick = _a.onClick, KeyboardArrow = _a.KeyboardArrow, KeyboardArrowOpen = _a.KeyboardArrowOpen;
        return (_jsx(ListItemStyled, { children: _jsxs(ListItemButton, __assign({ onClick: onClick ? onClick : function () { } }, { children: [icon, _jsx(ItemTexto, { text: text }), KeyboardArrow && _jsx(KeyboardArrowDownIcon, { sx: { transform: KeyboardArrowOpen ? 'rotate(180deg)' : 'none' } })] })) }));
    };
    var SubItemMenu = function (props) {
        if (!props.ignorar_permissoes && !mostrarItemMenu(props.pagina_nome)) {
            return _jsx(_Fragment, {});
        }
        return (_jsx(ListItemStyled, { children: _jsx(ListItemButton, __assign({ onClick: function () {
                    navigate(props.pagina_link);
                } }, { children: _jsx(ItemTexto, { text: props.pagina_nome }) })) }));
    };
    return (_jsxs(Box, __assign({ className: 'Sidebar', sx: {
            position: { xs: 'fixed', md: 'sticky' },
            transform: {
                xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                md: 'none',
            },
            transition: 'transform 0.4s, width 0.4s',
            zIndex: 999,
            minHeight: '80dvh',
            width: 'var(--Sidebar-width)',
            top: 0,
            p: 0,
            pt: 3,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRight: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.paper',
            paddingTop: 5,
        } }, { children: [_jsx(GlobalStyles, { styles: function (theme) {
                    var _a;
                    return ({
                        ':root': (_a = {
                                '--Sidebar-width': '220px'
                            },
                            _a[theme.breakpoints.up('lg')] = {
                                '--Sidebar-width': '240px',
                            },
                            _a),
                    });
                } }), _jsx(Box, { className: 'Sidebar-overlay', sx: {
                    position: 'fixed',
                    zIndex: 999,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }, onClick: function () { return closeSidebar(); } }), _jsx(Box, { sx: { display: 'flex', gap: 0, alignItems: 'center' } }), _jsx(Box, __assign({ sx: {
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 0.5,
                    display: 'flex',
                    flexDirection: 'column',
                } }, { children: _jsxs(List, __assign({ sx: {
                        gap: 1,
                        '--List-nestedInsetStart': '0px',
                    } }, { children: [_jsx(ItemMenu, { onClick: function () {
                                navigate(ROUTES.HOME_PAINEL);
                            }, icon: _jsx(HomeRoundedIcon, {}), text: 'SIRS - In\u00EDcio' }), _jsx(Toggler, __assign({ renderToggle: function (_a) {
                                var open = _a.open, setOpen = _a.setOpen;
                                return (_jsx(ItemMenu, { icon: _jsx(LibraryBooksIcon, {}), text: 'Contratos e Conv\u00EAnios', onClick: function () { return setOpen(!open); }, KeyboardArrow: true, KeyboardArrowOpen: open }));
                            } }, { children: _jsxs(List, __assign({ sx: { gap: 0, p: 0 } }, { children: [_jsx(SubItemMenu, { pagina_nome: PAGINAS.CC_INSTITUICOES, pagina_link: ROUTES.CC_INSTITUICOES }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.CC_TIPOS_EMPRESAS, pagina_link: ROUTES.CC_TIPOS_EMPRESAS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.CC_TIPOS_DOCUMENTOS, pagina_link: ROUTES.CC_TIPOS_DOCUMENTOS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.CC_TIPOS_CONTRATOS, pagina_link: ROUTES.CC_TIPOS_CONTRATOS })] })) })), _jsx(Toggler, __assign({ renderToggle: function (_a) {
                                var open = _a.open, setOpen = _a.setOpen;
                                return (_jsx(ItemMenu, { icon: _jsx(ShieldIcon, {}), text: 'Seguran\u00E7a', onClick: function () { return setOpen(!open); }, KeyboardArrow: true, KeyboardArrowOpen: open }));
                            } }, { children: _jsxs(List, __assign({ sx: { gap: 0, p: 0 } }, { children: [_jsx(SubItemMenu, { pagina_nome: PAGINAS.USUARIOS, pagina_link: ROUTES.USUARIOS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.PERFIS, pagina_link: ROUTES.PERFIS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.DEPARTAMENTOS, pagina_link: ROUTES.DEPARTAMENTOS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.ORGAOS_EMPRESAS, pagina_link: ROUTES.ORGAOS_EMPRESAS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.TIPOS_SERVIDORES, pagina_link: ROUTES.TIPOS_SERVIDORES })] })) })), _jsx(Toggler, __assign({ renderToggle: function (_a) {
                                var open = _a.open, setOpen = _a.setOpen;
                                return (_jsx(ItemMenu, { icon: _jsx(StorageIcon, {}), text: 'Tabelas B\u00E1sicas', onClick: function () { return setOpen(!open); }, KeyboardArrow: true, KeyboardArrowOpen: open }));
                            } }, { children: _jsxs(List, __assign({ sx: { gap: 0, p: 0 } }, { children: [_jsx(SubItemMenu, { pagina_nome: PAGINAS.CIDADES, pagina_link: ROUTES.CIDADES }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.ESTADOS, pagina_link: ROUTES.ESTADOS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.ORGAOS_EMISSORES_RG, pagina_link: ROUTES.ORGAOS_EMISSORES_RG }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.TIPOS_LOGRADOUROS, pagina_link: ROUTES.TIPOS_LOGRADOUROS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.ESTADOS_CIVIS, pagina_link: ROUTES.ESTADOS_CIVIS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.GRAUS_ESCOLARIDADE, pagina_link: ROUTES.GRAUS_ESCOLARIDADE }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.NIVEIS_ESCOLARIDADE, pagina_link: ROUTES.NIVEIS_ESCOLARIDADE }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.CATEGORIAS_CNH, pagina_link: ROUTES.CATEGORIAS_CNH }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.ORIENTACOES_SEXUAIS, pagina_link: ROUTES.ORIENTACOES_SEXUAIS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.IDENTIDADES_GENERO, pagina_link: ROUTES.IDENTIDADES_GENERO }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.TIPOS_RACAS_CORES, pagina_link: ROUTES.TIPOS_RACAS_CORES }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.TIPOS_CONTAS_BANCARIAS, pagina_link: ROUTES.TIPOS_CONTAS_BANCARIAS }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.AGENCIAS_BANCARIAS, pagina_link: ROUTES.AGENCIAS_BANCARIAS })] })) })), DEV && (_jsx(Toggler, __assign({ renderToggle: function (_a) {
                                var open = _a.open, setOpen = _a.setOpen;
                                return (_jsx(ItemMenu, { icon: _jsx(SettingsRoundedIcon, {}), text: 'Configura\u00E7\u00F5es', onClick: function () { return setOpen(!open); }, KeyboardArrow: true, KeyboardArrowOpen: open }));
                            } }, { children: _jsxs(List, __assign({ sx: { gap: 0, p: 0 } }, { children: [_jsx(SubItemMenu, { pagina_nome: PAGINAS.FUNCIONALIDADES, pagina_link: ROUTES.FUNCIONALIDADES, ignorar_permissoes: false }), _jsx(SubItemMenu, { pagina_nome: PAGINAS.ACOES, pagina_link: ROUTES.ACOES, ignorar_permissoes: false })] })) })))] })) })), _jsx(Box, __assign({ pl: 2 }, { children: _jsx(Typography, __assign({ style: { fontSize: FONT_SIZE.smallLarge } }, { children: "Vers\u00E3o ".concat(getVersao()) })) })), _jsx(Divider, {})] })));
};
export default Sidebar;
